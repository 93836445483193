/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";
import ImageViewer from "react-simple-image-viewer";

import SEO from "../components/seo";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { trackEvent } from "../analytics";

// @ts-ignore
import art1 from "../images/artwork/art_1.png";
// @ts-ignore
import art2 from "../images/artwork/art_2.jpeg";
// @ts-ignore
import art3 from "../images/artwork/art_3.jpeg";
// @ts-ignore
import art4 from "../images/artwork/art_4.jpeg";
// @ts-ignore
import art5 from "../images/artwork/art_5.jpeg";
// @ts-ignore
import art6 from "../images/artwork/art_6.jpeg";

export default function Resources(props: PageProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);

  const openImageViewer = React.useCallback((index) => {
    setCurrentIndex(index);
    setIsViewerOpen(true);
    const item = images[index];
    trackEvent("open_artwork_image", {
      title: item.analyticsTitle,
      position: index + 1,
    });
  }, []);

  const closeImageViewer = React.useCallback(() => {
    setCurrentIndex(0);
    setIsViewerOpen(false);
    const item = images[currentIndex];
    trackEvent("close_artwork_image", {
      title: item.analyticsTitle,
      position: currentIndex + 1,
    });
  }, [currentIndex]);

  return (
    <div className="bg-white">
      <SEO title="Artwork" description="" image={art1} />
      {isViewerOpen ? null : <NavBar {...props} />}
      <main>
        <div className="relative bg-my-tan pt-8 sm:pt-16 pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
          <div className="absolute inset-0">
            <div className="bg-white h-1/3 sm:h-2/3" />
          </div>
          <div className="relative max-w-7xl mx-auto">
            <h3 className="sm:text-4xl text-3xl font-extrabold tracking-tight text-my-orange ">
              Artwork
            </h3>
            <div className="mt-12 mx-auto grid gap-5 sm:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="bg-white flex flex-col rounded-lg shadow-lg overflow-hidden"
                >
                  <img
                    className="flex-shrink-0 h-96 object-cover object-top"
                    onClick={() => openImageViewer(index)}
                    style={{ marginTop: -80 }}
                    src={image.src}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <Footer />
      {isViewerOpen && (
        <ImageViewer
          src={sources}
          currentIndex={currentIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </div>
  );
}

const images = [
  { analyticsTitle: "blue_background", src: art1 },
  { analyticsTitle: "skater", src: art2 },
  { analyticsTitle: "tree", src: art3 },
  { analyticsTitle: "clear_dress", src: art4 },
  { analyticsTitle: "earring_1", src: art5 },
  { analyticsTitle: "earring_2", src: art6 },
];
const sources = images.map((item) => item.src);
